import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  VKShareButton,
  VKIcon,
} from 'react-share';

import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config';
const fullConfig = resolveConfig(tailwindConfig);

const preset = {
  fb: true,
  linkedin: true,
  twitter: true,
  reddit: true,
  vk: false,
};

const size = 48;
const borderRadius = 6;
const bgStyle = { fill: fullConfig.theme.colors['medium-on-surface-0'] };
const iconFillColor = fullConfig.theme.colors['surface-0'];

const Facebook = ({ url }) => (
  <FacebookShareButton url={url}>
    <FacebookIcon
      size={size}
      borderRadius={borderRadius}
      bgStyle={bgStyle}
      iconFillColor={iconFillColor}
    />
  </FacebookShareButton>
);

const Linkedin = ({ url }) => (
  <LinkedinShareButton url={url}>
    <LinkedinIcon
      size={size}
      borderRadius={borderRadius}
      bgStyle={bgStyle}
      iconFillColor={iconFillColor}
    />
  </LinkedinShareButton>
);

const Twitter = ({ url }) => (
  <TwitterShareButton url={url}>
    <TwitterIcon
      size={size}
      borderRadius={borderRadius}
      bgStyle={bgStyle}
      iconFillColor={iconFillColor}
    />
  </TwitterShareButton>
);

const Reddit = ({ url }) => (
  <RedditShareButton url={url}>
    <RedditIcon
      size={size}
      borderRadius={borderRadius}
      bgStyle={bgStyle}
      iconFillColor={iconFillColor}
    />
  </RedditShareButton>
);

const Vk = ({ url }) => (
  <VKShareButton url={url}>
    <VKIcon
      size={size}
      borderRadius={borderRadius}
      bgStyle={bgStyle}
      iconFillColor={iconFillColor}
    />
  </VKShareButton>
);

const SocialShareButtons = ({ options = preset }) => {
  const { site } = useStaticQuery(graphql`
    query SiteDataQuery {
      site {
        siteMetadata {
          domainName
        }
      }
    }
  `);

  return (
    <div className="flex flex-col justify-center text-center">
      <p className="text-lg text-medium-on-surface-0 pb-2">
        Please help spread the word by sharing. Thanks!
      </p>
      <div className="flex justify-center">
        {options.fb && (
          <div className="p-2">
            <Facebook url={site.siteMetadata.domainName} />
          </div>
        )}
        {options.linkedin && (
          <div className="p-2">
            <Linkedin url={site.siteMetadata.domainName} />
          </div>
        )}
        {options.twitter && (
          <div className="p-2">
            <Twitter url={site.siteMetadata.domainName} />
          </div>
        )}
        {options.reddit && (
          <div className="p-2">
            <Reddit url={site.siteMetadata.domainName} />
          </div>
        )}
        {options.vk && (
          <div className="p-2">
            <Vk url={site.siteMetadata.domainName} />
          </div>
        )}
      </div>
    </div>
  );
};

SocialShareButtons.propTypes = {
  options: PropTypes.object,
};

export default SocialShareButtons;
