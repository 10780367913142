import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import avatar from '../img/my-avatar.jpeg';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import SubscribeResponse from './SubscribeResponse';

const Subscribe = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors, clearErrors, reset } = useForm();

  const onSubmit = data => {
    setIsLoading(true);
    axios
      .post(`${process.env.API_URL || ''}/api/subscribe`, data)
      .then(function() {
        setIsLoading(false);
        reset();
        setOpen(true);
      })
      .catch(function(error) {
        setIsLoading(false);
        console.log(error);
      });
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <div className="rounded bg-surface-02 text-lg">
      <SubscribeResponse open={open} closeModal={closeModal} />
      <div
        className="md:flex md:flex-no-wrap md:justify-start my-2 p-4 pb-0 text-medium-on-surface-2"
        htmlFor="email"
      >
        <div className="flex-shrink-0 md:ml-1 md:mr-4 text-center md:text-left py-4 md:py-1">
          <PreviewCompatibleImage
            imageInfo={{
              image: avatar,
              alt: "It's me around 5 years ago",
            }}
            style={{ overflow: 'initial' }}
            className="h-20 w-20 rounded-full inline-block"
          />
        </div>
        <div>
          <div className="text-left">
            Hi, my name is Sandor. I am going full steam to make on-line learning more fun!
          </div>
          <div className="py-2 md:py-1 text-left">Subscrbe to get notified when Beta is out.</div>
          <div className="pb-4 md:pb-2 text-left text-xs text-medium-on-surface-0">
            Your email will NOT be stored until you confirm your subscription.
          </div>
          <div>
            {' '}
            <form className="md:inline-block w-full" onSubmit={handleSubmit(onSubmit)}>
              <div className="md:flex md:flex-no-wrap py-2 md:py-4 md:pt-2">
                <div className="relative md:w-2/3 md:mr-4">
                  <input
                    className={`w-full bg-surface-02 text-high-on-surface-0 placeholder-disabled-on-surface-0 appearance-none border rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${
                      errors.email ? 'border-error' : 'border-surface-24'
                    }`}
                    name="email"
                    type="text"
                    placeholder="Email"
                    onChange={() => clearErrors()}
                    ref={register({
                      required: 'Email address is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'invalid email address',
                      },
                    })}
                  />
                  {errors.email && (
                    <div className="absolute mt-1 text-error text-xs">{errors.email.message}</div>
                  )}
                </div>
                <div className="relative md:w-1/3 flex-shrink-0 text-center py-2 pt-4 md:pt-0">
                  <input
                    type="submit"
                    disabled={isLoading}
                    className="py-2 px-4 bg-transparent text-accent font-medium cursor-pointer"
                    value={isLoading ? 'Working ...' : 'Subscribe'}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
