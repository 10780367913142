import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { EmailIcon } from '../img/icons';

const SubscribeResponse = ({ open, closeModal }) => (
  <Popup open={open} closeOnDocumentClick onClose={closeModal} className="emailConfModal">
    <div className="bg-surface-02 max-w-full md:max-w-xs py-2 mx-4 md:mx-0 rounded">
      <div className="flex justify-between px-4 pb-2 border-b border-disabled-on-surface-0">
        <div className="text-lg text-medium-on-surface-0"> Almost done </div>
        <a className="cursor-pointer text-disabled-on-surface-0" onClick={closeModal}>
          &times;
        </a>
      </div>
      <div className="my-8 text-base px-4">
        <div className="text-center">
          <EmailIcon className="inline-block fill-current h-12 w-12 mb-5 text-disabled-on-surface-0" />
        </div>
        <p className="mb-1 md:mb-0">We need to confirm your email address.</p>
        <p>Please click the link in the email we just sent you.</p>
      </div>
      <div className="flex justify-center mb-4">
        <button className="text-accent" onClick={closeModal}>
          OK
        </button>
      </div>
    </div>
  </Popup>
);

SubscribeResponse.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default SubscribeResponse;
